import React from 'react';
import './App.css';

function App() {
  return (
    <div className="container">
      <div className="content">
        {/* 头像部分，可以替换成你自己的图片 */}
        <div className="name">

          <p>Yuyao Gao</p>

        </div>

        <p>
          I live in Toronto.<br />
          I build and design sites.
        </p>

        <p>
          Tel: (819)880-5777<br />
          Email: yuyaogao5777@gmail.com<br />
          Wechat:LoganGao725
        </p>

        <div className="Portfolios">
          <span>Portfolios:</span>
          <br />
          <a href="https://www.sankoufurniture.com/" target="_blank" rel="noopener noreferrer">
            Sankou Interior Inc.
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
